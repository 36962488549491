<template>
  <LayoutBase class="topnav-default">
    <template
      v-if="!!slots['sidebar-left']"
      #sidebar-left
    >
      <slot name="sidebar-left" />
    </template>

    <template #default>
      <slot name="page-header" />

      <LayoutBaseContent full-width>
        <slot name="default" />

        <template
          v-if="!!slots['sidebar-right']"
          #sidebar-right
        >
          <slot name="sidebar-right" />
        </template>
      </LayoutBaseContent>
    </template>
  </LayoutBase>
</template>

<script setup lang="ts">
const slots = defineSlots<{
  default(): any
  'page-header'(): any
  'sidebar-left'(): any
  'sidebar-right'(): any
}>()
</script>
